<i18n>
ru:
  accrual: Бонусы к начислению
  delivery: Доставка
  gratuity: Чаевые команде
  gratuityTooltip: На втором этапе оформления заказа вы можете отключить чаевые команде
  count: 'Нет товаров | {n} товар | {n} товара | {n} товаров'
  serviceFee: Сервисный сбор
  priorityDelivery: Приоритетная доставка
  priorityCooking: Приоритетное приготовление
  tax: Налог
  writeOff: Бонусы к списанию
ua:
  accrual: Бонуси до нарахування
  delivery: Доставка
  count: 'Нет позиций | {n} позиция | {n} позиции | {n} позиций'
  gratuity: Чаї для команди
  gratuityTooltip: На другому етапі оформлення замовлення ви можете вимкнути чаї для команди
  serviceFee: Сервісний збір
  priorityCooking: Пріоритетне приготування
  priorityDelivery: Пріоритетна доставка
  tax: Податок
  writeOff: Бонуси до списання
us:
  accrual: Bonuses for accrual
  delivery: Delivery
  count: 'no items | one item | {count} items'
  gratuity: Tips for the team
  gratuityTooltip: 'At the second stage of placing an order, you can disable tips for the team'
  serviceFee: Service fee
  priorityCooking: Priority cooking
  priorityDelivery: Priority delivery
  tax: Tax
  writeOff: Bonuses for write-off
</i18n>

<template>
  <ul
    class="v-d-flex v-flex-column v-cart-summary-rhudaur"
    v-if="!!clientStore.ClientState?.data?.Cart"
  >
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      :text="translate('summaryPage.count', { n: totalAmount }, totalAmount)"
      :amount="clientStore.ClientState.data.Cart.ToPayWithMoneyActive.Amount"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="
        appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryEnabled &&
        clientStore.courierDelivery &&
        clientStore.ClientState.data.PriorityService?.Active
      "
      :text="translate('summaryPage.priorityDelivery')"
      :amount="clientStore.ClientState.data.PriorityService?.Cost"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-else-if="
        appConfig.RestaurantSettingsPreRun.PriorityServicePickupEnabled &&
        clientStore.selfService &&
        clientStore.ClientState.data.PriorityService?.Active
      "
      :text="translate('summaryPage.priorityCooking')"
      :amount="clientStore.ClientState.data.PriorityService?.Cost"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="
        appConfig.RestaurantSettingsPreRun.BonusSystemEnabled &&
        accountStore.isLoggedIn &&
        pointsAccrual > 0
      "
      :text="translate('summaryPage.accrual')"
      type="points-accrual"
      :amount="pointsAccrual"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="
        appConfig.RestaurantSettingsPreRun.BonusSystemEnabled &&
        accountStore.isLoggedIn &&
        pointsWithdraw > 0
      "
      :text="translate('summaryPage.writeOff')"
      type="points-withdraw"
      :amount="pointsWithdraw * -1"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="
        clientStore.ClientState.data.DeliveryPaymentInfo.DeliveryCost > 0 ||
        clientStore.ClientState.data.DeliveryPaymentInfo.FreeDelivery
      "
      :text="translate('summaryPage.delivery')"
      :amount="clientStore.ClientState.data.DeliveryPaymentInfo.DeliveryCost"
      :amount-modified="
        clientStore.ClientState.data.DeliveryPaymentInfo.ModifiedDeliveryCost
      "
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="(clientStore.ClientState.data.StateOrderData?.Gratuity ?? 0) > 0"
      :text="translate('summaryPage.gratuity')"
      :tooltip-text="translate('summaryPage.gratuityTooltip')"
      :amount="clientStore.ClientState.data.StateOrderData?.Gratuity"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="clientStore.ClientState.data.ServiceFee?.Active"
      :text="translate('summaryPage.serviceFee')"
      :amount="clientStore.ClientState.data.ServiceFee?.Cost"
    />
    <cart-rhudaur-summary-single
      :in-second-stage="inSecondStage"
      v-if="clientStore.ClientState.data?.Cart.ToPayWithMoneyActive.Tax > 0"
      :text="translate('summaryPage.tax')"
      :amount="clientStore.ClientState.data?.Cart.ToPayWithMoneyActive.Tax"
    />
  </ul>
</template>

<script setup lang="ts">
const { inSecondStage = false } = defineProps<{ inSecondStage?: boolean }>()

const appConfig = useAppConfig()

const { translate } = useI18nSanitized()
const { items } = useCart()

const accountStore = useAccountStore()
const clientStore = useClientStore()

const totalAmount = computed<number>(() => {
  if (items.value.length === 0) return 0

  return items.value
    .filter((item) => !item.Removed)
    .map((m) => m.Count)
    .reduce((item1, item2) => {
      return item1 + item2
    })
})

const pointsAccrual = computed<number>(() => {
  return clientStore.ClientState?.data?.Cart?.PointsAccrual ?? 0
})
const pointsWithdraw = computed<number>(() => {
  const paidWithPoints =
    clientStore.ClientState?.data?.Cart?.ToPayWithPoints?.Amount ?? 0
  const partialPayPoints =
    clientStore.ClientState?.data?.Cart?.ToPayWithPartialPayPoints?.Amount ?? 0

  return paidWithPoints + partialPayPoints
})
</script>

<style lang="scss">
.v-cart-summary-rhudaur {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
</style>
